<template>
  <c-box>
    <c-stack is-inline :spacing="['0', '20px']" >
      <c-button
        as="router-link"
        class="m-tabs"
        :to="{ name: 'nutri.progress-tracker.indikator' }"
        variant="ghost"
        rounded="0"
        :w="['50%', 'auto']"
        white-space="braek-space"
        font-size="12px"
      >
        Indikator Progress & Tracker
      </c-button>
      <c-button
        as="router-link"
        class="m-tabs"
        :to="{ name: 'nutri.progress-tracker.master' }"
        variant="ghost"
        rounded="0"
        :w="['50%', 'auto']"
        white-space="braek-space"
        font-size="12px"
      >
        Master Progress & Tracker
      </c-button>
    </c-stack>

    <router-view />
  </c-box>
</template>

<script>
export default {
  name: "NutriProgressTracker",
};
</script>

<style scoped>
.m-tabs {
  border-color: transparent;
  border-bottom-width: 5px;
}
.m-tabs.router-link-active {
  border-bottom-width: 5px;
  border-color: #008C81;
  color: #008C81;
}
</style>
